/* You can add global styles to this file, and also import other style files */
@import 'node_modules/novo-elements/novo-elements.scss';
@import "hint";
@import "novo-elements";

.error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $grey;
    margin: 2rem;
  
    i {
      font-size: 4rem;
      margin: 1rem;
    }
  
    .details {
      text-align: center;
    }
  }
  
  .custom-tab {
    min-height: 100%;
    background-color: $white;
  
    .loading-container {
      display: flex;
      justify-content: center;
    }
  
    .description {
      text-align: center;
      margin: 2rem;
    }
  
    .content {
      .show-fields {
        margin-left: 1rem;
        margin-top: 1rem;
      }
  
      .form-container {
        padding: 21px 21px 40px;
        border-bottom: 1px solid #f4f4f4;
      }
  
      footer {
        display: flex;
        justify-content: flex-end;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 10px 15px;
        z-index: 100;
        background-color: $background;
  
        button {
          margin-left: 1rem;
        }
      }
    }
  }
  
  .headline-container {
    max-width: 1200px;
    padding-bottom: 20px;
  
    .form-column:last-of-type .column-headline {
      text-align: right;
    }
  }
  
  .form-row {
    display: flex;
    justify-content: flex-start;
  
    .form-label {
      white-space: nowrap;
      display: flex;
      align-items: center;
      font-weight: 400;
      text-transform: none;
      font-size: 14px;
      width: 180px;
    }
  
    .form-field {
      width: 100%;
      max-width: 500px;
    }
  
  }
  
  
  novo-spinner {
    max-width: 1200px;
    justify-content: center;
    display: flex;
  }
  
  .fixedWindowSize {
    height: 700px;
  }
  
  .results-container {
    padding: 20px;
  }
  
  .table-wrapper {
    padding: 30px 20px;
    box-shadow: 0 3px 7px rgba(0, 0, 0, .15);
    width: 100%;
    max-width: 1200px;
  }
  
  novo-data-table {
    height: 100%;
  }
  
  novo-data-table .novo-data-table-outside-container-fixed .novo-data-table-container-fixed {
    position: relative;
  }
  
  .value-cell {
    cursor: pointer;
    min-height: 35px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    opacity: .5;
    transition: all .1s linear;
  
    &:hover {
      // border: 2px solid #4a89dc;
      opacity: 1;
      background-color: #4a89dc;
      color: #fff;
    }
  
    &.selected {
      font-weight: bold;
      // border: 2px solid #4a89dc;
      opacity: 1;
      background-color: #4a89dc;
      color: #fff;
    }
  }
  
  .saveCompleted {
    color: #8CC152;
    font-size: 30px;
    text-align: center;
    font-weight: bold;
  }
  
  novo-data-table header {
    padding: 0 !important;
  }